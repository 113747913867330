export default Object.freeze({
  ACTIVE: 'active',
  ADS_TXT: 'adstxt',
  ALLOWED_USERS: 'allowed_users',
  BIDDERS: 'bidders',
  CONTRACT_PARAMETERS: 'contract_parameters',
  DIFF: 'diff',
  FEES: 'fees',
  NOTE: 'note',
  PLAINPASSWORD: 'plainPassword',
  PRESIZED_DIVS: 'presized_divs',
  PUBLISH: 'publish',
  ROLE: 'role',
  SELLERS_JSON_ORGANIZATION: 'sellers_json_organization',
  SERVICE_STATUS: 'service_status',
  TAGGING: 'tagging',
  UNLOCK: 'unlock',
  USER_GROUP: 'user_group',
  UUID: 'uuid',
  VALIDATED: 'validated'
})
