import store from '@store'
import views from '@enums/views'
import endpoints from '@enums/endpoints'
import statusCode from '@enums/statusCode'
import aclActions from '@enums/aclActions'
import aclObjects from '@enums/aclObjects'

const AccountsList = () =>
  import(/* webpackChunkName: "accounts-list" */ '@views/accounts/AccountsList.vue')
const AccountsTabs = () =>
  import(/* webpackChunkName: "accounts-tabs" */ '@views/accounts/AccountsTabs.vue')
const AccountsManager = () =>
  import(/* webpackChunkName: "accounts-manager" */ '@views/accounts/AccountsManager.vue')
const AccountsOrganizations = () =>
  import(
    /* webpackChunkName: "accounts-organizations" */ '@views/accounts/AccountsOrganizations.vue'
  )
const AccountsWebsites = () =>
  import(
    /* webpackChunkName: "accounts-websites" */ '@views/accounts/AccountsWebsites.vue'
  )

const appBreadcrumb = [
  {
    text: 'accounts',
    to: { name: views.ACCOUNTS }
  },
  {
    text: () => store.getters['accounts/editedAccountFullName'],
    active: true
  }
]

export default [
  {
    path: `/${views.ACCOUNTS}`,
    name: views.ACCOUNTS,
    component: AccountsList,
    meta: {
      appBreadcrumb: [
        {
          text: 'accounts',
          active: true
        }
      ],
      action: aclActions.LIST,
      object: [aclObjects.USER, aclObjects.ALL_USERS]
    }
  },
  {
    path: `/${views.ACCOUNTS}/edit/:id(\\d+)`,
    redirect: { name: views.ACCOUNTS_EDIT_GENERAL },
    name: views.ACCOUNTS_EDIT,
    component: AccountsTabs,
    beforeEnter: async (to, from, next) => {
      try {
        const userIRI = `${endpoints.USERS}/${to.params.id}`
        await store.dispatch('accounts/fetchItem', userIRI)

        const userPermissions = store.state.user?.permissions
        const isSuperAdmin = store.getters['user/isSuperAdmin']

        // User can be allowed to organizations
        const userHasOrganizations = userPermissions[aclActions.UPDATE].includes(aclObjects.USER_HAS_ORGANIZATIONS)
        if (userHasOrganizations || isSuperAdmin) {
          const { data: { enable } } = await store.$services.usersService.getUserHasAction(userIRI, {
            action: aclActions.LIST,
            object: aclObjects.ALL_ORGANIZATIONS
          })
          store.commit('accounts/setAllocableToResource', {
            enable: !enable,
            key: 'allocableToOrganizations'
          })
        }

        // User can be allowed to websites
        const userHasWebsites = userPermissions[aclActions.UPDATE].includes(aclObjects.USER_HAS_WEBSITES)
        if (userHasWebsites || isSuperAdmin) {
          const { data: { enable } } = await store.$services.usersService.getUserHasAction(userIRI, {
            action: aclActions.LIST,
            object: aclObjects.ALL_WEBSITES
          })
          store.commit('accounts/setAllocableToResource', {
            enable: !enable,
            key: 'allocableToWebsites'
          })
        }
        next()
      } catch (error) {
        error?.response?.status === statusCode.UNAUTHORIZED
          ? next(false)
          : next({
            name: views.RESOURCE_NOT_FOUND,
            params: { target: views.ACCOUNTS_EDIT }
          })
      }
    },
    children: [
      {
        path: '',
        name: views.ACCOUNTS_EDIT_GENERAL,
        component: AccountsManager,
        meta: {
          appBreadcrumb,
          action: aclActions.GET,
          object: [aclObjects.USER, aclObjects.ALL_USERS]
        }
      },
      {
        path: 'organizations',
        name: views.ACCOUNTS_EDIT_ORGANIZATIONS,
        component: AccountsOrganizations,
        meta: {
          appBreadcrumb,
          action: aclActions.UPDATE,
          object: aclObjects.USER_HAS_ORGANIZATIONS
        }
      },
      {
        path: 'websites',
        name: views.ACCOUNTS_EDIT_WEBSITES,
        component: AccountsWebsites,
        meta: {
          appBreadcrumb,
          action: aclActions.UPDATE,
          object: aclObjects.USER_HAS_WEBSITES
        }
      }
    ]
  }
]
