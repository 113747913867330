import store from '@store'
import views from '@enums/views'
import endpoints from '@enums/endpoints'
import statusCode from '@enums/statusCode'
import aclActions from '@enums/aclActions'
import aclObjects from '@enums/aclObjects'
import aclFields from '@enums/aclFields'

const WebsitesList = () =>
  import(/* webpackChunkName: "websites-list" */ '@views/websites/WebsitesList.vue')
const WebsitesTabs = () =>
  import(/* webpackChunkName: "websites-tabs" */ '@views/websites/WebsitesTabs.vue')
const WebsitesManager = () =>
  import(/* webpackChunkName: "websites-manager" */ '@views/websites/WebsitesManager.vue')
const WebsitesFees = () =>
  import(/* webpackChunkName: "websites-fees" */ '@views/websites/WebsitesFees.vue')
const WebsitesConfigs = () =>
  import(/* webpackChunkName: "websites-configs" */ '@views/websites/WebsitesConfigs.vue')
const WebsitesAdsTxt = () =>
  import(/* webpackChunkName: "websites-ads-txt" */ '@views/websites/WebsitesAdsTxt.vue')
const WebsitesPresizedDivs = () =>
  import(/* webpackChunkName: "websites-presized-divs" */ '@views/websites/WebsitesPresizedDivs.vue')
const WebsitesMonitoring = () =>
  import(/* webpackChunkName: "websites-monitoring" */'@views/websites/WebsitesMonitoring.vue')
const WebsitesWordpressDebug = () =>
  import(/* webpackChunkName: "websites-wordpress-debug" */'@views/websites/WebsitesWordpressDebug.vue')
const WebsitesBidders = () =>
  import(/* webpackChunkName: "websites-bidders" */'@views/websites/WebsitesBidders.vue')

const appBreadcrumb = [
  {
    text: 'websites',
    to: { name: views.WEBSITES }
  },
  {
    text: () => store.getters['websites/editedItemName'],
    active: true
  }
]

export default [
  {
    path: `/${views.WEBSITES}`,
    name: views.WEBSITES,
    component: WebsitesList,
    meta: {
      appBreadcrumb: [
        {
          text: 'websites',
          active: true
        }
      ],
      action: aclActions.LIST,
      object: [aclObjects.WEBSITE, aclObjects.ALL_WEBSITES]
    }
  },
  {
    path: `/${views.WEBSITES}/create`,
    name: views.WEBSITES_CREATE,
    component: WebsitesManager,
    props: {
      action: 'create'
    },
    meta: {
      appBreadcrumb: [
        {
          text: 'websites',
          to: { name: views.WEBSITES }
        },
        {
          text: 'create',
          active: true
        }
      ],
      action: aclActions.CREATE,
      object: aclObjects.WEBSITE
    }
  },
  {
    path: `/${views.WEBSITES}/edit/:id(\\d+)`,
    redirect: { name: views.WEBSITES_EDIT_GENERAL },
    name: views.WEBSITES_EDIT,
    component: WebsitesTabs,
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('websites/fetchItem', `${endpoints.WEBSITES}/${to.params.id}`)
        next()
      } catch (error) {
        error?.response?.status === statusCode.UNAUTHORIZED
          ? next(false)
          : next({
            name: views.RESOURCE_NOT_FOUND,
            params: { target: views.WEBSITES_EDIT }
          })
      }
    },
    children: [
      {
        path: '',
        name: views.WEBSITES_EDIT_GENERAL,
        component: WebsitesManager,
        meta: {
          appBreadcrumb,
          action: aclActions.GET,
          object: [aclObjects.WEBSITE, aclObjects.ALL_WEBSITES]
        }
      },
      {
        path: 'fees',
        name: views.WEBSITES_EDIT_FEES,
        component: WebsitesFees,
        meta: {
          appBreadcrumb,
          action: aclActions.GET,
          object: aclObjects.WEBSITE,
          field: aclFields.FEES
        }
      },
      {
        path: 'configs',
        name: views.WEBSITES_EDIT_CONFIGS,
        component: WebsitesConfigs,
        meta: {
          appBreadcrumb,
          action: aclActions.LIST,
          object: aclObjects.WEBSITE_CONFIG
        }
      },
      {
        path: 'ads-txt',
        name: views.WEBSITES_EDIT_ADS_TXT,
        component: WebsitesAdsTxt,
        meta: {
          appBreadcrumb,
          action: aclActions.GET,
          object: aclObjects.WEBSITE_CONFIG,
          field: aclFields.ADS_TXT
        }
      },
      {
        path: 'presized-divs',
        name: views.WEBSITES_EDIT_PRESIZED_DIVS,
        component: WebsitesPresizedDivs,
        meta: {
          appBreadcrumb,
          action: aclActions.GET,
          object: aclObjects.WEBSITE_CONFIG,
          field: aclFields.PRESIZED_DIVS
        }
      },
      {
        path: 'monitoring',
        name: views.WEBSITES_EDIT_MONITORING,
        component: WebsitesMonitoring,
        meta: {
          appBreadcrumb,
          action: aclActions.GET,
          object: aclObjects.WEBSITE
        }
      },
      {
        path: 'wordpress-debug',
        name: views.WEBSITES_EDIT_WORDPRESS_DEBUG,
        component: WebsitesWordpressDebug,
        meta: {
          appBreadcrumb,
          action: aclActions.GET,
          object: aclObjects.WORDPRESS_TOKEN_DEBUG
        }
      }
    ]
  },
  {
    path: `/${views.WEBSITES}-bidders`,
    name: views.WEBSITES_BIDDERS,
    component: WebsitesBidders,
    meta: {
      appBreadcrumb: [
        {
          text: 'websitesBidders',
          active: true
        }
      ],
      action: aclActions.LIST,
      object: aclObjects.WEBSITE_BIDDERS
    }
  }
]
