import views from '@enums/views'
import aclActions from '@enums/aclActions'
import aclObjects from '@enums/aclObjects'

const AclRulesList = () =>
  import(/* webpackChunkName: "acl-rules-list" */ '@views/acl-rules/AclRulesList.vue')

export default [
  {
    path: `/${views.ACL_RULES}`,
    name: views.ACL_RULES,
    component: AclRulesList,
    meta: {
      appBreadcrumb: [
        {
          text: 'aclRules',
          active: true
        }
      ],
      action: aclActions.LIST,
      object: aclObjects.ALL_ACL
    }
  }
]
