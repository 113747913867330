export default Object.freeze({
  DEFAULT: 'MinusIcon',
  ACCOUNT: 'AppUserIcon',
  ACCOUNTS: 'UsersIcon',
  ACL_RULES: 'ShieldIcon',
  ACTIVITY: 'ActivityIcon',
  ADS_TXT: 'FileTextIcon',
  ADVANCED_EDITION: 'SettingsIcon',
  ANALYTICS: 'AppAnalyticsIcon',
  ASSOCIATE: 'UserPlusIcon',
  AT_SIGN: 'AtSignIcon',
  AUDIENCE: 'AppUsersGroupIcon',
  BACK: 'ChevronLeftIcon',
  BIDDERS: 'AppBidderIcon',
  BIDDERS_TEMPLATES_MANAGER: 'BookOpenIcon',
  CODE: 'CodeIcon',
  CONFIGS: 'HashIcon',
  CONSUMPTION: 'EyeIcon',
  COPY: 'CopyIcon',
  CREATE: 'PlusSquareIcon',
  DELETE: 'Trash2Icon',
  DIFF: 'GitPullRequestIcon',
  DOC: 'FileTextIcon',
  DOWNLOAD: 'DownloadIcon',
  DRAG_DROP: 'AppDragDropIcon',
  DYNAMIC: 'CpuIcon',
  EDIT: 'EditIcon',
  EVENT: 'ShuffleIcon',
  FEES: 'DollarSignIcon',
  GDPR: 'AppGDPRIcon',
  GENERAL: 'FolderIcon',
  HOMEPAGE: 'HomeIcon',
  INFO: 'InfoIcon',
  INJECTION: 'TerminalIcon',
  IN_IMAGE: 'ImageIcon',
  LINK: 'LinkIcon',
  LOCK: 'LockIcon',
  LOGOUT: 'LogOutIcon',
  MAIL: 'MailIcon',
  MEDIAS: 'ImageIcon',
  MONETIZATION: 'AppMonetIcon',
  MONITORING: 'EyeIcon',
  MOVE: 'MoveIcon',
  NO_ADS: 'AppProhibitedIcon',
  NOTE: 'TypeIcon',
  ORGANIZATIONS: 'AppOrganizationIcon',
  PRESIZED_DIVS: 'SlidersIcon',
  PUBLISH: 'AirplayIcon',
  PURGE: 'WindIcon',
  REVOKE: 'XSquareIcon',
  SLOTS: 'LayoutIcon',
  TAGGING: 'SettingsIcon',
  TARGETS: 'CrosshairIcon',
  TEMPLATE: 'GridIcon',
  UNLINK: 'UserMinusIcon',
  UNLOCK: 'UnlockIcon',
  WEBSITES: 'GlobeIcon',
  WORDPRESS: 'AppWordpressIcon'
})
